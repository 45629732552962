import React from "react"
import styled from "styled-components"
import Parser from "html-react-parser"
import Img from "gatsby-image"

import globalVariables from "../../globalVariables"
import { QuoteAltLeft } from "styled-icons/boxicons-solid/QuoteAltLeft"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Text = styled.div`
  p {
    text-align: center;
    margin-top: 1.5rem;
    color: ${props => props.theme.bodyColor};
    @media (min-width: ${globalVariables.minTablet}) {
      width: 60%;
      margin-right: auto;
      margin-left: auto;
    }
  }
`
const Client = styled.p`
  color: ${props => props.theme.headerColor};
  font-family: Rajdhani;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 1rem 0;
`

const QuoteAltLeftStyled = styled(QuoteAltLeft)`
  size: "1.5rem";
  color: ${props => props.theme.secondary};
  width: 40px;
  height: 40px;
`

const WrapperImage = styled.div`
  .gatsby-image-wrapper {
    width: 250px;
    height: 250px;
    @media (min-width: ${globalVariables.bigDesktop}) {
      width: 300px;
      height: 300px;
    }
  }
  img {
    /* border-radius: 50%; */
  }
`

const Quote = ({ client, text, image }) => {
  return (
    <Wrapper>
      {image ? (
        <WrapperImage>
          <Img fluid={image} />
        </WrapperImage>
      ) : (
        <QuoteAltLeftStyled />
      )}

      <Text>{Parser(text)}</Text>
      <Client>{client}</Client>
    </Wrapper>
  )
}

export default Quote
