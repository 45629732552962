import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Section from "../../elements/Section/index"
import Container from "../../elements/Container/index"
import Quote from "../../elements/Quote"

const Temoignages = ({ items, colorSection }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Section color={colorSection}>
      <Container>
        <Slider {...settings}>
          {items.map((item, index) => (
            <Quote
              key={index}
              text={item.contenu.html}
              client={item.nom.text}
              image={item.photo.fluid}
            />
          ))}
        </Slider>
      </Container>
    </Section>
  )
}

export default Temoignages
